import React from 'react';
import './becomeposp.css';
import bimg1 from './images/3.png';
import icon1 from './images/Group 7234.png';
import icon12 from './images/Group 7235.png';
import icon13 from './images/Group 7236.png';
import icon14 from './images/Group 7237.png';
import icon15 from './images/Group 7238.png';
import icon16 from './images/Group 7239.png';
import imgw1 from './images/simplify.png';
import imgw12 from './images/heart.png';
import imgw13 from './images/official-documents.png';
import Rimg1 from './images/review1.png';
import { Accordion } from 'react-bootstrap';
import AccordionNew from '../../Component/AccordionNew/Accordion';
import Form from '../../Component/Form/form';
import { Helmet } from 'react-helmet';


export class BecomePOSP extends React.Component {
  render() {
    return (

      <section>

        <Helmet>
          <title>Become a Certified POSP Agent Online with Nivesh Insurance - Best POSP Insurance Company</title>
          <meta name="description" content="Nivesh Insurance - Best POSP Insurance Company. Become POSP Insurance Agent online in 5 hassle-free steps!" />
        </Helmet>
        <div id="hero-becomeposp">
          <div className="container">
            <div className="flex-becomeposp">
              <div id="posp-heading">
                <h2 className="becposp-heading">Become Online ‘Point of Sales Person’ in Insurance - Join as POSP with Nivesh Insurance</h2>
                <img src={bimg1} alt="" width="60%" />
              </div>

              <Form />
            </div>
            <p>Become an online ‘point of sales person’ (POSP) (insurance agent) with Nivesh Insurance and grow your business digitally. These days, every business is turning digital and survival will be impossible if you don’t adopt technology for your business. If you have a network of clients, you can grow your income by adding insurance products to your business. If you are already selling insurance, you can add more products and insurance companies by joining Nivesh Insurance as POSP.<br />
              Nivesh Insurance is a leading insurance broker in India. Our online platform will help you execute business in a seamless manner.<br />
              We are popular for our client-centric approach and determination to provide the finest insurance policies.
            </p>
          </div>
        </div>

        <div id="benefits-posp">
          <div className="container">
            <h2 className="benefitsposp-heading">Benefits of Becoming a ‘Point of Sales Person’ (POSP) with Nivesh Insurance</h2>
            <div class="benefitsposp-flex">
              <div class="benefitsposp-flex1">
                <img src={icon1} alt="" />
                <p>Go digital: Buy insurance online </p>
              </div>

              <div class="benefitsposp-flex1">
                <div className="imgbox-posp">
                  <img src={icon12} alt="" />
                </div>
                <p>Product training & knowledge sharing sessions </p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon13} alt="" />
                <p>Products of all insurance companies</p>
              </div>
            </div>
            <div class="benefitsposp-flex">
              <div class="benefitsposp-flex1">
                <img src={icon14} alt="" />
                <p>Everything at one place: Life, Health & General</p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon15} alt="" />
                <p>High level of transparency and ethics</p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon16} alt="" />
                <p>Access to relationship manager for support</p>
              </div>
            </div>
          </div>
        </div>

        <div id="cta">
          <div class="container">
            <div class="cta-button">
              <a href="/become-posp-insurance-agent">
                <button id="cta-button1" type="button" name="button">Become POSP</button></a>
            </div>
          </div>
        </div>

        <div id="steps-posp">
          <div className="container">
            <h2 className="stepposp-heading">How to Become a POSP with Nivesh Insurance?</h2>
            <p className='text-center'>Becoming a POSP with Nivesh Insurance is simple and can be completed in few steps:</p>
            <div class="stepposp-gridA">
              <div class="stepposp-grid1">
                <h2 className="steps">Step1</h2>
                <p>Register yourself online</p>
                <a href="/become-posp-insurance-agent" target="_self">
                  <button id="step-button" type="button" name="button">Get Started</button></a>

              </div>

              <div class="stepposp-grid1">
                <h2 className="steps">Step2</h2>
                <p>Undertake online training</p>
              </div>

              <div class="stepposp-grid1">
                <h2 className="steps">Step3</h2>
                <p>Pass the online examination</p>
              </div>


            </div>
            <div class="stepposp-gridB">
              <div class="stepposp-grid1">
                <h2 className="steps">Step4</h2>
                <p>Receive the certificate to work as POSP with Nivesh Insurance</p>
              </div>

              <div class="stepposp-grid1">
                <h2 className="steps">Step5</h2>
                <p>Start selling insurance products to your customers</p>
              </div>


            </div>
          </div>
        </div>


        <div id="why-samavesh">

          <div className="container">
            <h2 class="why-head">Why Nivesh Insurance</h2>

            <div class="why-group">

              <div class="why-box">
                <img src={imgw1} alt="" />
                <p>Licensed Insurance Broker: Licensed by Insurance Regulatory & Development Authority of India (IRDAI) (license number 769 valid upto 2nd September 2024)</p>

              </div>
              <div class="why-box">
                <img src={imgw12} alt="" />
                <p>Tie-up with all insurance companies in life, health and general insurance</p>

              </div>
              <div class="why-box">
                <img src={imgw13} alt="" />
                <p>Experienced management team, who have long experience in Insurance and Financial Services domain</p>

              </div>

            </div>
          </div>
        </div>

        <div id="cta">
          <div class="container">
            <div class="cta-button">
              <a href="/become-posp-insurance-agent">
                <button id="cta-button1" type="button" name="button">Become POSP</button></a>
            </div>
          </div>
        </div>

        {/*
               <div id="review-section">
                 <div className="container">
                   <h2 className="review-title">Customer Review</h2>
                   <div className="review-flex">
                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>
                   </div>

                   <div className="review-flex1">
                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>
                   </div>
                 </div>
               </div> */}


        {/*
               <div id="video-box">
               <div className="container">
                <div className="video-flex">
                  <h2 className="video-heading">Learn about all the features of the Samavesh</h2>
                  <div className="video-box">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/35npVaFGHMY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>

               </div>
                </div> */}

        <AccordionNew />
      </section>
    );
  }
}
export default BecomePOSP;