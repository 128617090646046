import React from 'react';
import './Home.css';
import logo from './images/1.png';
import imgp1 from './images/insurance 3.png';
import imgp12 from './images/healthcare 1.png';
import imgp13 from './images/003-sedan.png';
import imgp14 from './images/004-house-1.png';
import imgp15 from './images/002-travel.png';
import imgpp1 from './images/employee health.png';
import imgpp12 from './images/employee health.png';
import imgpp13 from './images/001-fire.png';
import imgpp14 from './images/005-shop.png';
import imgpp15 from './images/003-house.png';

import imgw1 from './images/simplify.png';
import imgw12 from './images/heart.png';
import imgw13 from './images/official-documents.png';
import imgf1 from './images/featured-news.png';
import icon1 from './images/venn-diagram 1.png';
import icon12 from './images/laptop.png';
import icon13 from './images/presentation.png';
import icon14 from './images/booking.png';
import icon15 from './images/social-marketing.png';
import icon16 from './images/money.png';
import { NavLink } from 'react-router-dom';

export class Home extends React.Component {
  render() {
    return (
      <section>
        <div id="hero-section">
          <div className="container">
            <div className="flex-hero">
              <div id="heading">
                <h2 className="banner-heading">Assure yourself with the right insurance plan!</h2>
                <h2 className="banner-heading2">Let the certified Nivesh Insurance Advisor help you</h2>
                <a href="/become-posp-insurance-agent">
                  <button id="become-posp" type="button" name="button">Become POSP</button></a>
                <a href="/insurance-plans">
                  <button id="insurance" type="button" name="button">Insurance Plan</button></a>
              </div>

              <div className="banner-img">
                <img src={logo} alt="" width="90%"></img>
              </div>
            </div>
          </div>
        </div>

        <div id="benefits-posphome">
          <div className="container">
            <h2 className="posphome-heading">Benefits of Buying Insurance with Nivesh Insurance</h2>
            <div class="posp-flex">
              <div class="posp-flex1">
                <img src={icon1} alt="" />
                <p>Digital experience: Buy insurance online </p>
              </div>

              <div class="posp-flex1">
                <img src={icon12} alt="" />
                <p>Insure all your requirements: Life, Health, Motor & Fire</p>
              </div>

              <div class="posp-flex1">
                <img src={icon13} alt="" />
                <p>Products of all insurance companies</p>
              </div>
            </div>
            <div class="posp-flex">
              <div class="posp-flex1">
                <img src={icon14} alt="" />
                <p>High level of transparency and ethics</p>
              </div>

              <div class="posp-flex1">
                <img src={icon15} alt="" />
                <p>Access to relationship manager for assistance</p>
              </div>

              <div class="posp-flex1">
                <img src={icon16} alt="" />
                <p>Support in claim process</p>
              </div>
            </div>
          </div>
        </div>


        <div id="cta">
          <div class="container">
            <div class="cta-button">
              <a href="/become-posp-insurance-agent">
                <button id="cta-button1" type="button" name="button">Become POSP</button></a>
            </div>
          </div>
        </div>

        <div id="insurance-plans">
          <div className="container">
            <h2 class="plans-head">Insurance Plan</h2>
            <button id="individual" type="button" name="button">Individual</button>

            <div class="individual-group">
              <div class="individual-box">
                <img src={imgp1} alt="" />
                <p>Life / Term insurance</p>
              </div>

              <div class="individual-box">
                <img src={imgp12} alt="" />
                <p>Health</p>
              </div>

              <div class="individual-box">
                <img src={imgp13} alt="" />
                <p>Motor</p>
              </div>

              <div class="individual-box">
                <img src={imgp14} alt="" />
                <p>Home Insurance</p>
              </div>
              <div class="individual-box">
                <img src={imgp15} alt="" />
                <p>Travel</p>
              </div>
            </div>

            <button id="individual" type="button" name="button">Business</button>
            <div class="individual-group">
              <div class="individual-box">
                <img src={imgpp1} alt="" />
                <p>Group Health</p>
              </div>

              <div class="individual-box">
                <img src={imgpp12} alt="" />
                <p>Group Life</p>
              </div>

              <div class="individual-box">
                <img src={imgpp13} alt="" />
                <p>Fire</p>
              </div>

              <div class="individual-box">
                <img src={imgpp14} alt="" />
                <p>Property</p>
              </div>
              <div class="individual-box">
                <img src={imgpp15} alt="" />
                <p>Director & Officers Liability</p>
              </div>
            </div>
          </div>
        </div>


        <div id="why-samavesh">

          <div className="container">
            <h2 class="why-head">Why Nivesh Insurance </h2>

            <div class="why-group">

              <div class="why-box">
                <img src={imgw1} alt="" />
                <p>icensed Insurance Broker: Licensed by Insurance Regulatory & Development Authority of India (IRDAI) (license number 769 valid upto 2nd September 2024)</p>
                {/*<button id="why-button" type="button" name="button">Read More</button>*/}
              </div>
              <div class="why-box">
                <img src={imgw12} alt="" />
                <p>Tie-up with all insurance companies in life, health and general insurance</p>
                {/*<button id="why-button" type="button" name="button">Read More</button>*/}
              </div>
              <div class="why-box">
                <img src={imgw13} alt="" />
                <p>Experienced management team, who have long experience in Insurance and Financial Services domain</p>
                {/*<button id="why-button" type="button" name="button">Read More</button>*/}
              </div>

            </div>
          </div>
        </div>


        {/**
    <div id="grad">
      <div class="container">
      <div class="grad-head">
        <h2 class="grad-heading">Lorem Ipsum dolor </h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac condimentum libero.</p>
        </div>
       <div class="grad-group">
         <div class="grad-box1">
           <h2 class="number-head">1000+</h2>
           <p>Lorem ipsum dolor sit</p>
         </div>
         <div class="grad-box2">
         <h2 class="number-head">1000+</h2>
           <p>Lorem ipsum dolor sit</p>
         </div>
         <div class="grad-box3">
         <h2 class="number-head">1000+</h2>
           <p>Lorem ipsum dolor sit</p>
         </div>

        </div>
        </div>
    </div>

         <div id="featured-news">
           <div class="container">
           <div class="featured-head">
              <h2 class="featured-head">Featured News</h2>
              <img src={imgf1} alt="" width="100%"/>

            </div>
            </div>
          </div>  */}
      </section>
    );
  }
}

export default Home;
