import React from 'react';
import { Helmet } from 'react-helmet';
import './policy.css';

export class Policy extends React.Component {
  render() {
    return (

      <main>
        <Helmet>
          <title>Privacy Policy- Nivesh Insurance</title>
          <meta name="description" content="Privacy Policy of niveshinsurance.com" />
        </Helmet>
        <div id="privacy-policy">
          <div class="container">
            <h2 class="h12">Privacy Policy</h2>
            <h3>Introduction</h3>
            <p>This Privacy Policy sets out how the information provided by you is collected, used, stored, processed, transferred and protected. Please read the terms carefully. By accessing the Website or using any of our services, You agree to be bound by all the terms of this Privacy Policy.
            </p>

            <h3>What is Nivesh insurance?</h3>
            <p>Nivesh Insurance is a brand name of SAMAVESH INSURANCE BROKERS PRIVATE LIMITED. The website “www.niveshinsurance.com” along with its “Website” and/or Mobile Application (“App”) is owned and operated by Samavesh Insurance Brokers Private Limited (“Nivesh Insurance /We”) and is a Direct Broker (Life, Non-Life & Health) registered by IRDAI vide registration code IRDA/DB856/21 Valid upto 02-09-2024 date 03-09-2021 and renewable thereafter from time to time. Name of its Principal Officer is Dr. Hira Lal Jaiswal and his contact details are :
              <br />
              Email : hira.jaiswal@niveshinsurance.com<br />
              Mobile No: +91-9871175020
            </p>

            <h3>How We Collect And Use Your Personal Information</h3>
            <p>Prior to registration – You can access certain sections and features of Nivesh insurance without registering or disclosing any personal information. As a visitor to the Website/App, please note that your web browser or computer makes anonymous visitor data available to us in the form of HTTP cookies. We use this data together with similar data from other visitors to understand which areas of Nivesh insurance are the most popular and how to best present and customize our Website/App.<br/><br/>
              Registration – To avail of our services you will be asked to register for an account. This involves creating a username and password (collectively, “Login Credentials”) and disclosing to us sensitive information that can identify you personally like your email address, mobile number, correspondence address, and financial information such as your bank account or other payment instrument details (collectively, your “Registration Information”).<br/><br/>
              Other Information which Nivesh Insurance collect is :<br/>
              a) Personal Information that will mean and include all kind of information which can be linked to a specific individual or to identify any individual, directly or indirectly, such as name, age, address, contact details (phone numbers/ email address), date of birth.<br/>
              b) Clickstream data that includes information about your interaction with various elements of our Website and App.<br/>
              c) If appropriate permissions are provided by you, we collect the GPS location on various platforms that you use the service on Website/App. Location might be collected at certain times that might depend on your Website usage or the time of the day.<br/>
              d) Vehicle information whenever opting for motor insurance.<br/>
              e) Health and lifestyle information which is collected only as per the requirements of the insurer to underwrite the proposal as per the guidelines of Insurance Regulatory and Development Authority of India (“IRDAI”) for health & life insurance.<br/>
              f) Health and lifestyle related questions/information, which are mandated by the insurance company for the purpose of issuing insurance policy.<br/>
              g) Details required in a KYC document which is the mandatory document as per the guidelines of IRDAI.<br/>
              h) Documentary proof/information of educational qualifications, wherever applicable<br/>
              i) Financial data including bank account number, IFSC Code.<br/>
              j) Constitution details of a vendor including without limitation, name, office address, Articles of Association.<br/>
              k) Biometric Information, wherever applicable.<br/>
              We may also ask for additional personal information from time to time to meet new regulatory requirements or to provide you with other benefits of our services. As always, the information that we seek will only be for the purpose stated.
              We use this information to contact you and undertake activities that you have specifically authorized us to do.

            </p>

            <h3>How You Can Access And Update Your Personal Information</h3>
            <p>Your personal information is yours. To access or update your information, please login and make changes as required. Some of these changes may require you to provide documentary evidence. In any event, upon your request, Nivesh insurance has a legal obligation and will provide every registered user of the website and services with a readable copy of the personal data that we keep about you. As an added security measure, we may require additional proof of identity prior to such disclosure.<br/>
              Remember to keep your Login Credentials safe and secret to prevent unauthorized access to your account. If you think that the security of your account has been compromised, change your password and contact us immediately for further assistance.

            </p>

            <h3>Sharing Your Personal Information</h3>
            <p>Under no circumstance will we sell or rent your personal information to anyone, for any reason, at any time.<br/>
              Information collected will be used for the following purposes:<br/>
              a) For issuance of the insurance policy that you have opted for.<br/>
              b) For providing information about various products and services.<br/>
              c) For addressing queries put forth by you and for resolving the concerns pertaining to any service or product.<br/>
              d) For processing your transactions and also to provide you transaction and post transaction-related services.<br/>
              e) For providing, improving, and marketing our products and services, including site content and performance.<br/>
              f) For sending you survey and marketing communications.<br/>
              g) For facilitating various programs and initiatives launched either by us or third party which we believe may be of an interest to you.<br/>
              h) For facilitating usage of our Website/App.<br/>
              i) For improving our services, product or content on our Website/App.<br/>
              j) For providing group insurance cover and wellness corner offerings.<br/>
              k) For providing health and wellness related information to you that are offered by insurer.<br/>
              l) For sending notices, communications, offer alerts and to contact you over the telephone/ mobile number and/or e-mail address provided by you for sending information of our products in which you have shown your interest, policy renewal reminders, other service  details and other general information about insurance<br/>
              m) For appointment as Point of Salesperson /employee.<br/>
              n) For payment of salary/commission earned and related circumstances therein.<br/>
              o) For verification purposes.<br/>
              p) For the purpose of joining of the employee and for validation of the details of the curriculum vitiate.<br/>
              q) For bank account details will be used for the purpose of salary/commission credit to employees/POSP and for payment to vendors/suppliers.<br/>
              r) For biometric information for attendance purposes.<br/>
              s) To improve our products and enhance customer service.<br/>
              t) To extend services or administer a contest, promotion, survey or other site or business feature.<br/>
              u) For Aadhaar authentication and sharing, storing, using Aadhaar data.<br/>
              v) For sales and marketing Activities.<br/>
              w) Allow you to access specific account information.<br/>
              x) To process transactions, where requested, under your User ID and Password. All the data/information collected may be stored on the infrastructure provided by third party cloud service in India which is fully compliant with regulatory requirements within. No information/data that is collected ever goes out of India.<br/>
              All our employees have been selected in accordance with our security policies and practices and are bound by confidentiality obligations. They are subject to stern disciplinary action, including termination, civil liability, and criminal prosecution, if they fail to meet these obligations.<br/>
              We may also use third party vendors or service providers to help us provide services to you, such as couriering documents to you on our behalf or operating the payment gateway functionality of the Website/App. Such third parties are required to maintain the confidentiality of the information we provide to them under contract and under law.<br/>

            </p>

            <h3>Your Information Will Always Be Safe With Us</h3>
            <p>Nivesh insurance has appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure and/or destruction of data. All stored information is appropriately encrypted and secured against unauthorized access.
            </p>

            <h3>Retention of Information</h3>
            <p>All information collected by us will be stored with us as long as it is necessary for us to fulfill our obligations in respect of the provision of the services and in accordance with the applicable laws. Information collected by us can be retained by us if we believe it to be necessary to prevent fraud or future abuse; if required by law, for other legitimate purposes.
            </p>

            <h3>Our Legal Obligations To You</h3>
            <p>We are bound by the (Indian) Information Technology Act, 2000, and comply with all its provisions. Under Section 43A of the (Indian) Information Technology Act, 2000, Nivesh insurance is obliged to maintain reasonable security procedures to safeguard your data.<br/>
              Under Regulation 4 of the Information Technology (Reasonable Security and Procedures and Sensitive Personal Data or Information) Rules, 2011, Nivesh insurance is obliged to provide every registered user of Nivesh insurance with a readable copy of the information that it keeps about you. If you would like a copy, do get in touch with us at the address provided below.
            </p>

            <h3>Changes To This Privacy Policy</h3>
            <p>This Privacy Policy is published on the website in compliance with Regulation 4 of the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 .<br/>
              We update this Privacy Policy periodically. We will not reduce your rights under this Privacy Policy without your explicit consent. We will notify you of any changes to this Privacy Policy.

            </p>

            <h3>Reach Out To Us</h3>
            <p>If you have any questions or concerns on our services or policies, you can reach our Grievance Officer at the details provided below:
              Name : Dr..Hira Jaiswal <br />
              Email: hira.jaiswal@samavesch.com<br />
              Phone: +91-9871175020

            </p>


          </div>
        </div>
      </main>
    );
  }
}

export default Policy;