import React from 'react';
import './aboutus.css';
import icon1 from '../Home/images/solution.png';
import icon2 from '../Home/images/values.png';
import icon3 from '../Home/images/target.png';
import icon4 from '../Home/images/touch-screen-phone-in-human-hands-inside-a-circle.png';
import icon5 from '../Home/images/insurance.png';
import { Helmet } from 'react-helmet';

import icon from '../InsurancePlans/images/Ellipse 21 (1).png';
import { Accordion } from 'react-bootstrap';
import AccordionNew from '../../Component/AccordionNew/Accordion';
export class Aboutus extends React.Component {
  render() {
    return (

      <section>
        <Helmet>
          <title>About Us | Nivesh Insurance | Licensed by IRDA  </title>
          <meta name="description" content="Nivesh Insurance Brokers Private Limited  is a Direct Insurance Broker licensed by (IRDAI) (license number 769 valid upto 2nd September 2024)." />
        </Helmet>
        <div id="about">
          <div className="container">
            <div col-lg-12>
              <h2 className>About Us</h2>
              <p>
              Samavesh Insurance Brokers Private Limited (Nivesh Insurance) is a Direct Broker (Life & General) registered by IRDAI vide Registration Code IRDA/DB856/21, Certificate of Registration No. 769 valid upto 02-09-2024 dated 03-09-2021.<br /><br />

                Nivesh Insurance is a wholly owned subsidiary of Providential Platforms Private Limited, which operates <a href="https://www.nivesh.com/">Nivesh.com</a> , a digital-first award winning investment platform. It provides various investment products like mutual funds, corporate fixed deposits, bonds, sovereign gold bonds (SGBs), non convertible debentures (NCDs), etc.

              </p>
            </div>
          </div>
        </div>
        <div id="benefits-posp1">
          <div className="container">
            <h2>Here is what is unique about our business model: </h2>
            <div class="benefitsposp-flex">
              <div class="benefitsposp-flex1">
                <img src={icon1} alt="" />

                <p>Comprehensive solutions, whether for individuals or for businesses</p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon2} alt="" />

                <p>Our guiding philosophy: Transparency and ethical business practices</p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon3} alt="" />

                <p>Focus on selling the right products for the needs of the customers</p>
              </div>
            </div>
            <div class="benefitsposp-flex">
              <div class="benefitsposp-flex1">
                <img src={icon4} alt="" />

                <p>Providing access to underserved segments through combination of Technology and Human Touch</p>
              </div>

              <div class="benefitsposp-flex1">
                <img src={icon5} alt="" />

                <p>Independent and neutral platform for buying Insurance</p>
              </div>
            </div>
          </div>
        </div>


        {/*
      <div id="business-leaders">
          <div className="container">
                    <h2>Meet Our Business Leaders</h2>
                    <div className="col-sm-4 team">
                              <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>
                    <div className="col-sm-4  team">
                    <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>
                    <div className="col-sm-4  team">
                    <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>
                    <div className="col-sm-4 team">
                    <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>
                    <div className="col-sm-4 team">
                    <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>
                    <div className="col-sm-4 team">
                    <img src={icon} alt="" />
                              <h4>Lorem ipsum dolor</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi felis vel.</p>
                    </div>

                </div>
      </div>

       <div id="table-aboutus">
                 <div className="container">
                           <h3>We have been issued an Insurance Broking License by the Insurance Regulatory and Development Authority of India (IRDAI) Details :</h3>
                 <table class="table">
                 <tbody>
                   <tr>
                     <th scope="row">Broking Firm Name</th>
                     <td>Neque dictum lectus eu nisl massa vulputate in.</td>
                   </tr>
                   <tr>
                     <th scope="row">License No</th>
                     <td>License No</td>
                   </tr>
                   <tr>
                     <th scope="row">License Code</th>
                     <td>Ornare ut erat pellentesque ullamcorper id venenatis.</td>
                   </tr>
                   <tr>
                     <th scope="row">Principal Officer</th>
                     <td>Condimentum cursus maecenas molestie sed.</td>
                   </tr>
                   <tr>
                     <th scope="row">Address</th>
                     <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing sed magna aliquet leo.</td>
                   </tr>
                   <tr>
                     <th scope="row">Phone</th>
                     <td>123456789</td>
                   </tr>
                   <tr>
                     <th scope="row">Category of Certificate</th>
                     <td>Maecenas ac vitae magna vitae amet leo urna.</td>
                   </tr>
                   <tr>
                     <th scope="row">Email of Applicant</th>
                     <td>Support@samavesh.com</td>
                   </tr>
                   <tr>
                     <th scope="row">License Validity</th>
                     <td>License Validity</td>
                   </tr>

                 </tbody>
                 </table>

                 </div>
       </div>

       <AccordionNew /> */}

      </section>
    );
  }
}
export default Aboutus;