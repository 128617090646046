import React from 'react';
import './insuranceplans.css';
import bimg1 from './images/2.png';
import imgp1 from './images/insurance 3.png';
import imgp12 from './images/healthcare 1.png';
import imgp13 from './images/003-sedan.png';
import imgp14 from './images/004-house-1.png';
import imgp15 from './images/002-travel.png';
import imgpp1 from './images/employee health.png';
import imgpp12 from './images/employee health.png';
import imgpp13 from './images/001-fire.png';
import imgpp14 from './images/005-shop.png';
import imgpp15 from './images/003-house.png';
import icon1 from './images/laptop.png';
import imgw1 from './images/simplify.png';
import Rimg1 from './images/review1.png';
import imgw12 from './images/heart.png';
import imgw13 from '../Home/images/official-documents.png';
import AccordionNew from '../../Component/AccordionNew/Accordion';
import Form from '../../Component/Form/form';
import { Helmet } from 'react-helmet';



export class InsurancePlans extends React.Component {
  render() {
    return (
      <section>
        <Helmet>
          <title>Buy or Renew Insurance Plan Online with Nivesh Insurance</title>
          <meta name="description" content="Get the Best Insurance Policy Online & Secure a Stable Future for Your Loved ones. Get financial security with easy & flexible plans with Nivesh Insurance."/>
        </Helmet>
          <div id="hero-becomeposp">
             <div className="container">
               <div className="flex-becomeposp">
                  <div id="posp-heading">
                      <h2 className="becposp-heading">The finest insurance broker in India - Nivesh Insurance</h2>
                      <img src={bimg1} alt="" width="60%"/>
                      <p>Get the best insurance policy online and secure a stable future for your loved ones along with financial security with Nivesh Insurance- your one stop destination for all your online insurance policies.</p>
                  </div>
                       <Form isInsurance={true} />
                 </div>
            </div>
           </div>

           <div id="insurance-plans">
             <div className="container">
                 <h2 class="plans-head">Insurance Plan</h2>
                 <button id="individual" type="button" name="button">Individual</button>

               <div class="individual-group">
                 <div class="individual-box">
                    <img src={imgp1} alt=""/>
                    <p>Life / Term insurance</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgp12} alt=""/>
                    <p>Health</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgp13} alt=""/>
                    <p>Motor</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgp14} alt=""/>
                    <p>Home Insurance</p>
                  </div>
                  <div class="individual-box">
                    <img src={imgp15} alt=""/>
                    <p>Travel</p>
                  </div>
              </div>

              <button id="individual" type="button" name="button">Business</button>
              <div class="individual-group">
                 <div class="individual-box">
                    <img src={imgpp1} alt=""/>
                    <p>Group Health</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgpp12} alt=""/>
                    <p>Group Life</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgpp13} alt=""/>
                    <p>Fire</p>
                  </div>

                  <div class="individual-box">
                    <img src={imgpp14} alt=""/>
                    <p>Property</p>
                  </div>
                  <div class="individual-box">
                    <img src={imgpp15} alt=""/>
                    <p>Director & Officers Liability</p>
                  </div>
              </div>
            </div>
          </div>

          <div id="cta">
            <div class="container">
              <div class="cta-button">
              <a href="/become-posp-insurance-agent">
               <button id="cta-button1" type="button" name="button">Become POSP</button></a>
               </div>
            </div>
          </div>

          <div id="benefits-posp1">
          <div className="container">
            <h2 className="benefitsposp-heading">Benefits of Buying Insurance</h2>
            <div class="benefitsposp-flex">
             <div class="benefitsposp-flex1">
                <img src={icon1} alt="" />
                <h4>Cover against Uncertainties</h4>
                <p>The insured individual is protected up to a certain extent, depending on the policy that they choose, against unforeseen negative circumstances.</p>
              </div>

              <div class="benefitsposp-flex1">
                  <img src={icon1} alt="" />
                  <h4>Investment opportunities</h4>
                  <p>There are certain insurance products that provide investment opportunities alongside protection. Such plans are known as Unit Linked Insurance Plans (ULIPs). In these plans a part of the premium paid if invested in market linked funds. </p>
              </div>

              <div class="benefitsposp-flex1">
               <img src={icon1} alt="" />
               <h4>Management of finances</h4>
                <p>Life is uncertain and such uncertain events can be a burden on the pocket if an individual has to pay for rectifying them himself/herself. An insurance policy can help tackle this uncertainty with ease. The chosen insurance provider pays in the event that the unforeseen event takes place which helps the individual maintain his hard earned money.</p>
               </div>
            </div>
        </div>
      </div>

      <div id="why-samavesh">

<div className="container">
  <h2 class="why-head">Why Nivesh Insurance </h2>

<div class="why-group">

  <div class="why-box">
    <img src={imgw1} alt="" />
    <p>Licensed Insurance Broker: Licensed by Insurance Regulatory & Development Authority of India (IRDAI) (license number 769 valid upto 2nd September 2024)</p>

   </div>
  <div class="why-box">
    <img src={imgw12} alt="" />
    <p>Tie-up with all insurance companies in life, health and general insurance</p>

  </div>
  <div class="why-box">
     <img src={imgw13} alt="" />
     <p>Experienced management team, who have long experience in Insurance and Financial Services domain</p>

  </div>

</div>
</div>
</div>

           <div id="cta">
            <div class="container">
              <div class="cta-button">
              <a href="/become-posp-insurance-agent">
               <button id="cta-button1" type="button" name="button">Become POSP</button></a>
               </div>
            </div>
          </div>


           {/*
          <div id="review-section">
                 <div className="container">
                   <h2 className="review-title">Customer Review</h2>
                   <div className="review-flex">
                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>
                   </div>

                   <div className="review-flex1">
                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>

                     <div className="review-box">
                       <img src={Rimg1} alt="" />
                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ex purus, elementum eget mi sit amet, rutrum luctus dui. Sed purus mauris, tristique quis leo quis, ullamcorper vulputate dui.</p>
                       <h2 className="review-name">Lorem ipsum dolor</h2>
                       <p>amet, consectetur adipiscing</p>
                     </div>
                   </div>
                 </div>
           </div> */}

               <AccordionNew />

     </section>
    );
  }
}

export default InsurancePlans;