import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './Navbar.css';
import logo from '../Header/Images/newLogo.jpg';
import {
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './HeaderElements';


function Navmenu() {
  return (
    <>
    <div className='top-bar'>
      <p className='text-center text-white'><b>SAMAVESH INSURANCE BROKERS PRIVATE LIMITED</b></p>
      </div>
    <div className="container">
      <div className="Navmenu">
        <Navbar variant="light"
          sticky="top" expand="md" collapseOnSelect>
          <Navbar.Brand>
            <a href="/">
              <img src={logo} width="200vw" height="auto" /></a>{' '}
          </Navbar.Brand>

          <Navbar.Toggle className="coloring" />
          <Navbar.Collapse className="right-aligned">
            <Nav>

              <Nav.Link href="/become-posp-insurance-agent">Become POSP</Nav.Link>
              <Nav.Link href="/insurance-plans">Insurance Plan</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>

              <NavBtnLink to='/'>English</NavBtnLink>
            </Nav>
          </Navbar.Collapse>

        </Navbar>
      </div>
    </div>
    </>

  );
}

export default Navmenu;