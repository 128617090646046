import React from 'react';
import './Accordion.css';
import  '../../../node_modules/font-awesome/css/font-awesome.min.css';
export class AccordianNew extends React.Component{
  render() {
      const title = 'Frequently Asked Questions';
      const hiddenTextsleft = [{
          label: 'What is Nivesh Insurance?',
          value: 'Nivesh Insurance is a licensed insurance broker (license number 769 valid upto 2nd September 2024) with Insurance Regulatory & Development Authority of India (IRDAI). The license enables it to sell life, health and general insurance products from all insurance companies in India.'
      },
      {
          label: 'Who is a ‘Point of Sales Person’ (POSP) in Insurance? ',
          value: 'A ‘point of sales person’ (POSP) in insurance is the person who has the required qualifications, has undertaken required training and has passed the qualification examination to act as POSP. '
      },
      {
          label: 'What is the advantage of becoming a ‘Point of Sales Person’ (POSP) in Insurance?',
          value: 'A POSP in Insurance can market products in life, health and general insurance from all insurance companies, which are pre-underwritten and approved by IRDAI. Becoming a POSP can be an excellent way to enhance your income.'
      },
      {
          label: 'What is the difference between an Insurance Agent and a ‘Point of Sales Person’ (POSP) in Insurance?',
          value: 'An insurance agent works directly with an insurance company and can only sell the products of this particular insurance company. While a POSP can sell products for all insurance companies in life, health and general insurance. '
      },
      {
          label: 'Do I need to have knowledge about Insurance for becoming a ‘Point of Sales Person’ (POSP) in Insurance?',
          value: 'No, you don’t need to have prior knowledge about insurance for becoming a POSP. You will be provided with required training to act as POSP by us.'
      }];

      const hiddenTextsright = [{
        label: 'What is Samavesh Insurance Brokers?',
        value: 'Samavesh Insurance Brokers is a licensed insurance broker (license number 769 valid upto 2nd September 2024) with Insurance Regulatory & Development Authority of India (IRDAI). The license enables it to sell life, health and general insurance products from all insurance companies in India.'
    },
    {
        label: 'Who is a ‘Point of Sales Person’ (POSP) in Insurance?',
        value: 'A ‘point of sales person’ (POSP) in insurance is the person who has the required qualifications, has undertaken required training and has passed the qualification examination to act as POSP. '
    }];
      return (

           <div id="accordian-new">
             <div className="row">
             <div className="container">
             <Header title={title} />

               <div className="row">

                 <div className="col-lg-12">
                    <Accordion hiddenTexts={hiddenTextsleft} />

                 </div>

               </div>
               </div>
             </div>
          </div>

      );
  }
}

class Header extends React.Component {
  render() {
      return (
          <h2>{this.props.title}</h2>
      );
  }
}

class Accordion extends React.Component {
  render() {
      return (
          <div className="accordion">
              {this.props.hiddenTexts.map((hiddenText) => <AccordionItem key={hiddenText.label} hiddenText={hiddenText} />)}
          </div>
      );
  }
}

class AccordionItem extends React.Component {

  constructor(props) {
      super(props);
      this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
      this.state = {
          visibility: false
      }
  }

  handleToggleVisibility() {
      this.setState((prevState) => {
          return {
              visibility: !prevState.visibility,
          }
      })
  }
  render() {
      const activeStatus = this.state.visibility ? 'active' : ''

      return (
          <div className="accordian-main">

              <button className="accordion__button" onClick={this.handleToggleVisibility}>{this.props.hiddenText.label}<span className={this.state.visibility? 'fas fa-minus': 'fas fa-plus'}></span></button>
              <p className={`accordion__content ${activeStatus}`}>
              {
                  this.props.hiddenText.value
              }
              </p>

          </div>
      );
  }
}

export default AccordianNew;