import React from 'react';
import './Footer.css';
import logo from "../Header/Images/newLogo.jpg"
export class Footer extends React.Component {
  render() {
    return (
       <div id="footer">
           <div class="container">
              <div class="flex-row">
                {/*
                     <div class="flex-1">
                        <div class='flex-11'>
                          <h4>Individual</h4>
                            <ul>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Life / Term insurance </a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Health</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Motor</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Home insurance</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Travel</a></li>
                            </ul>
                          </div>

                          <div class='flex-12'>
                            <h4>Business</h4>
                            <ul>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Employee Health </a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Employee Life</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Fire</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Shop</a></li>
                               <li><i class="bi bi-chevron-right"></i> <a href="#">Property</a></li>
                            </ul>
                          </div>
                </div> */}

                   <div class="flex-2">
                     <h4>Useful Links</h4>
                      <ul>

                        <li><i class="bi bi-chevron-right"></i> <a href="/contact-us">Contact Us</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="/terms-and-condition">Terms & Conditions</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="/privacy-and-policy">Privacy Policy</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="http://blog.niveshinsurance.com/">Blog</a></li>

                      </ul>
                   </div>

                    <div class="flex-3">
                     <h4>Agent & Partnership</h4>
                         <ul>
                          <li><i class="bi bi-chevron-right"></i> <a href="/become-posp-insurance-agent">Become Nivesh Insurance POSP</a></li>

                        </ul>
                     </div>
                  </div>

              <div className="footer-2">
                <img src={logo} alt="" />
                <p>A wholly owned subsidiary of Providential Platforms Private Limited <a href="https://www.nivesh.com" >(https://www.nivesh.com)</a></p>
                <p>SAMAVESH INSURANCE BROKERS PRIVATE LIMITED is a Direct Broker (Life & General) registered by IRDAI vide
Registration Code IRDA/DB856/21, Certificate of Registration No. 769 valid upto 02-09-2024 dated 03-09-2021.</p>

              </div>


          </div>
       </div>
    );
  }
}

export default Footer;