import React from "react";
import "./App.css";
import Router  from "./router";

import Header from "./Component/Header/Header"
import Footer from "./Component/Footer/Footer";
import Navbar from './Component/Navbar/Navbar';
import { Helmet } from "react-helmet";



class App extends React.Component {

  render() {
    return (
      <div className="landscape" >
        <div className="App">
          <Navbar />
          <Helmet>
            <title>Become IRDAI Certified POSP Insurance Agent with Nivesh Insurance</title>
            <meta name="description" content="Become an POSP Insurance Agent! Explore wide choices of Insurance Policies. IRDAI Certificatied." />
            </Helmet>
          <Router />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
