import React from 'react';
import './policy.css';
import { Helmet } from 'react-helmet';

export class Terms extends React.Component {
  render() {
    return (
      <main>

        <Helmet>
          <title>Terms & Conditions - Nivesh Insurance</title>
          <meta name="description" content="Terms & Conditions of niveshinsurance.com" />
        </Helmet>
        <div id="privacy-policy">
          <div class="container">
            <h2 class="h12">TERMS AND CONDITIONS</h2>
            <p>If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our Privacy Policy, govern SAMAVESH INSURANCE BROKERS PRIVATE LIMITED (“Nivesh Insurance”/“us”/“we”) relationship with you. Nivesh Insurance is a Direct Broker (Life, Non-Life & Health) registered by IRDAI vide registration code IRDA/DB856/21 Valid upto 02-09-2024 date 03-09-2021 and renewable thereafter from time to time.The term “SAMAVESH INSURANCE BROKERS PRIVATE LIMITED” refers to the owner of the website. The term “you” refers to the user or viewer of our website
            </p>

            <h3>THE USE OF THIS WEBSITE IS SUBJECT TO THE FOLLOWING TERMS OF USE</h3>
            <p>You understand and accept that Nivesh Insurance maintains the website https://www.niveshinsurance.com and related websites and mobile applications (going ahead referred to as “Site” or “Website”) to provide visitors with information about Nivesh Insurance, its services, and products and to facilitate communication with Nivesh Insurance and availing its services. You also accept that visitors to the Site are required to read the below terms and use of the Site constitutes your acceptance and agreement to be bound by such terms, and the changes therein to the terms from time to time, relating to your usage of the website as communicated or made available on the website.<br/><br/>
              You are aware and accept that all information, content, materials, products (including, but not limited to text, content, photographs, graphics, video and audio content) on the website is protected by copyright either in the favour of Nivesh Insurance or third parties whom the appropriate permissions have been taken under applicable copyright laws and is also protected otherwise under general intellectual property law. You understand and accept that all information, except your personal information, submitted by you through the Site shall be deemed the property of Nivesh Insurance, and Nivesh Insurance shall be free to use any ideas, concepts, know-how or techniques provided by you at the Site, in any manner whatsoever.<br/><br/>
              On initiating a contact through the Site you agree to be contacted by Nivesh Insurance or any other entities with whom Nivesh Insurance has entered into an arrangement. You agree that you shall not copy, reproduce, sell, redistribute, publish, enter into a database, display, perform, modify, transmit, license, create derivatives from, transfer or in any way exploit any part of any information, content, materials, services available from or through the Site, except that which you may download for your own personal, non-commercial use. You agree that you will not use Nivesh Insurance’s website for any purpose that is unlawful or prohibited by these Terms. You also agree you will not use the Site in any manner that could damage, disable or impair the website or interfere with any other party’s use, legal rights, or enjoyment of the website.<br/><br/>
              You acknowledge that the software and hardware underlying the Site as well as other Internet related software which are required for accessing the website are the legal property of the respective vendors. The permission given by Nivesh Insurance to access the website will not convey any proprietary or ownership rights in the above software/hardware. You agree that you shall not attempt to modify, translate, disassemble, decompile or reverse engineer the software/hardware underlying the website or create any derivative product based on the software/hardware. You understand that the data and information provided on the Website does not constitute advice and shall not be relied upon by you while making insurance decisions.<br/><br/>
              You understand and accept that not all the products and services offered on this website are available in all geographic areas and you may not be eligible for all the products or services offered by Nivesh Insurance on the Site. Nivesh Insurance reserves the right to determine the availability and eligibility for any product or service. You understand and accept that Nivesh Insurance is not responsible for the availability of content or other services on third party sites linked from the website. You are aware that access of hyperlinks to other internet sites are at your own risk and the content, accuracy, opinions expressed, and other links provided by these sites are not verified, monitored or endorsed by Nivesh Insurance in any way.<br/><br/>
              Nivesh Insurance shall not be liable if any transaction does not fructify or may not be completed or for any failure on part of the Nivesh Insurance to perform any of its obligations under these terms and conditions or those applicable specifically to its services/facilities if performance is prevented, hindered or delayed by a Force Majeure event (defined below) and in such case its obligations shall be suspended for so long as the Force Majeure event continues.<br/><br/>
              “Force Majeure Event” means any event due to any cause beyond the reasonable control of Providential, including without limitations, unavailability of any communication systems, breach, or virus in the processes or payment or delivery mechanism, sabotage, fire, flood, explosion, acts of god, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal or the systems getting affected by any malicious, destructive or corrupting code or program, mechanical or technical errors/failures or power shut down, faults or failures in telecommunication etc. By using the services provided through this Website, you shall be deemed to have accepted the Terms and Conditions herein including the amended terms and conditions published on the website.<br/><br/>
              You hereby irrevocably and unconditionally grant no objection to Nivesh Insurance and the respective vendors to collate the transaction details relating to the insurance done by you on the online technology platform of Nivesh Insurance and provide such transaction data to Nivesh Insurance for further processing of your transactions. You understand and accept that Nivesh Insurance has the absolute discretion to amend or supplement any of the terms at any time, without notice. By using Nivesh Insurance’s services, you shall be deemed to have accepted the changed terms and conditions. You accept that the Courts in Noida alone shall have exclusive jurisdiction as regards to any claims or matters arising out of dealings with Nivesh Insurance.<br/><br/>
              All disputes will be governed by the laws of India. In the event of dispute between You and Nivesh Insurance on accuracy of transaction details provided by you on the Nivesh Insurance Website, the transaction logs maintained by Nivesh Insurance back office will be the only source of data to verify the accuracy of such transactions. You understand and agree that these terms are in addition to, and not in derogation of, the applicable terms and conditions relating to your usage of any Nivesh Insurance services that you may be currently availing or may avail in the future.
            </p>

            <h3>LIMITATION OF LIABILITY</h3>
            <p>Nivesh Insurance shall not, in the absence of gross negligence on the part of Nivesh Insurance, be liable to you for any act, omission or delay by the mutual fund or for any claims which you may suffer or incur as a result of or in course or discharge by Nivesh Insurance or its employees, officers, directors, nominee or agent of Nivesh Insurance’s duties. Without prejudice to the above, Nivesh Insurance shall not be held liable for any loss or damage or failure to comply or delay in complying with its obligations under the Terms & Conditions which is caused directly or indirectly by any event or circumstances beyond Nivesh Insurance’s reasonable control.<br/><br/>
              These include system failure, network errors, delay or loss of data due to the above and in circumstances of acts of God, floods, epidemics, quarantine, riot, civil commotion and/or war. You further agree that Nivesh Insurance shall not be held liable for any losses, damages, expenses, costs, liabilities and claims of whatsoever nature caused by fraudulent or unauthorized use or access of your personal information, and/or signatures. Nivesh Insurance will be under no duty to verify compliance with any restrictions on your investment powers.<br/><br/>
              Nivesh Insurance will not be liable for any loss, damage, cost, charges or expenses directly or indirectly caused by reasons of any defects or imperfections or mechanical or other failure with relation to computer, cable, telex, telephone or postal system. While Nivesh Insurance will make every effort to have its computer systems available at all times, Nivesh Insurance makes no guarantees with respect to the availability of such systems. Nivesh Insurance will make every effort to resolve availability issues such as network problems, virus attacks etc. in an expeditious manner.<br/><br/>
              Notwithstanding these, Nivesh Insurance will as such not be liable for any loss, damage, cost, charges or expenses directly or indirectly caused by reasons of lack of such availability.Nivesh Insurance shall not be liable for any loss or damage caused by reason of failures or delay in delivery of services even though payments have been made for the same, or failure or delay in making payment in respect of said service, though they may have been delivered and you shall hold Nivesh Insurance harmless and free from any claim in respect thereof.<br/><br/>

              In conjunction with the Limitation of Warranties you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. Nivesh Insurance will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full the extent that applicable limitation of liability laws apply.<br/><br/>
              Any information contained in Nivesh Insurance brochures or other materials or otherwise communicated by Nivesh Insurance shall not be construed as investment advice and that all decisions to be made by you shall be on the basis of personal judgment arrived at after due consideration.
              By using Nivesh Insurance’s website, you understand and agree that all Resources we provide are “as is” and “as available”. This means that we do not represent or warrant to you that:<br/>
              • The use of Nivesh Insurance’s Resources will meet your needs or requirements.<br/>
              • The use of Nivesh Insurance’s Resources will be uninterrupted, timely, secure or free from errors.<br/>
              • The information obtained by using Nivesh Insurance’s Resources will be accurate or reliable, and<br/>
              • Any defects in the operation or functionality of any Resources we provide will be repaired or corrected.<br/>
              Nivesh Insurance does not make any promises to the user basis the graphical representation provided on the Website. Nivesh Insurance does not offer any advice and nothing herein or on the Nivesh Insurance Website shall be construed as isnurance advice by user. Any sum invested through Nivesh Insurance registered account is not a deposit with Nivesh Insurance and is not bank insured. The same is not endorsed or guaranteed and does not constitute obligations of Nivesh Insurance or any of the subsidiaries associates or affiliates companies whose role is only as described in the Terms & Conditions.
            </p>

            <h3>GUARANTEE</h3>
            <p>UNLESS OTHERWISE EXPRESSED, SAMAVESH INSURANCE BROKERS PRIVATE LIMITED EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

            <h3>Contact Information</h3>
            <p>If you have any questions or comments about these our Terms of Service as outlined above, you can contact us at:<br />
              Name: Dr. Hira Lal Jaiswal<br />
              Email: hira.jaiswal@niveshinsurance.com<br/>
              Mobile: +91-9871175020
            </p>



          </div>
        </div>
      </main>
    );
  }
}

export default Terms;