import React, { useState } from 'react';
import './form.css';
import Axios from 'axios';

function Form(props) {
  const url = "https://niveshinsurance.policyengine.in/pos/validate"
  const [data, setData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Insurancetype:""
  })

  const [insuranceFormSuccess, setInsuranceFormSuccess] = useState(false);

  const isValidMobile = (mobile) => {
    const regex = /^\d{10}$/;
    return regex.test(mobile);
  }

  const parseXm = (xmlStr) =>{

    return new window.DOMParser().parseFromString(xmlStr, "text/xml");
  }

  const isValidaName = () => { }

  const isValidEMail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    console.log(query)//"this should be everything after the ?"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) { return pair[1]; }
    }

    return (false);
  }

  const parseXml = (xmlStr) => {
    return new window.DOMParser().parseFromString(xmlStr, "text/xml");
  }

  const postInsuranceLead = () => {
    const url = `https://api.nivesh.com/API/WebAddLead`;

    const payload = {
      Name: data.Name,
      PhoneNo: data.Phone,
      Email: data.Email,
      Message: data.Insurancetype,
      IsDistributor: 0,
      TypeRequest: `SamaveshClientLead|${getQueryVariable('utm_campaign')}|${getQueryVariable('utm_content')}|${getQueryVariable('utm_medium')}|${getQueryVariable('utm_source')}`
    };

    const headers = {
      headers: { 'Content-Type': 'application/json' }
    };

    Axios.post(url, payload, headers)
      .then(res => {
        setInsuranceFormSuccess(true)
      });
  }




const sendPOSPData = () => {
    const body = {
      name: data.Name,
      phone_number: data.Phone,
      email: data.Email

  }


    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with':'XMLHttpRequest'
      }
    };

    Axios.post(url, body, headers)
      .then(res => {
        console.log(res.data.data);
        if (res.status==200){

          const responseObject = res.data;
          if (responseObject.status==1){
            window.location=`https://pos.niveshinsurance.com/#/otp-verification?phone_number=${responseObject.data.phone_number}`;
          } else {
            throw "Status Code is Success but something went wrong with payload";
          }
        } else if(res.status==422){
          alert("Email Already Exist");
        }
      })
      .catch(function(error){
        console.log(error);
      });
  }



  function submit(e) {
    e.preventDefault();
    const regexPhone=  /^[0-9]{10}$/;
     console.log(data.Phone.match(regexPhone));
    if(!(data.Email && data.Name && data.Phone && data.Phone.match(regexPhone)))
      return;
   console.log(`isInsurance:${props.isInsurance} data.email:${data.Email} data.phone:${data.Phone} data.Name:${data.Name}`);
    if(props.isInsurance){
      postInsuranceLead();
    } else {
      sendPOSPData();
    }
  }

  function handle(e) {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
    console.log(newdata)
  }



  return (
    <div class="contactme" id="contact">
      <div class="formWord">
        <div class="form-1">
          {!insuranceFormSuccess && <form onSubmit={(e) => submit(e)}>
            <input onChange={(e) => handle(e)} id="Name" value={data.Name} class="form_name" type="text" name="fullName" required placeholder="Enter Full Name As Per PAN " />

            <input onChange={(e) => handle(e)} id="Email" value={data.Email} class="form_email" type="email" name="phone" required placeholder="Enter Email ID" />

            {props.isInsurance && <select onChange={(e) => handle(e)} id="Insurancetype" name="Insurancetype" value={data.Insurancetype} class="select-insurance" >
            <option value="0">Select Insurance Plans</option>
            <option value="Life / Term insurance">Life / Term insurance</option>
            <option value="Healt">Health</option>
            <option value="Motor">Motor</option>
            <option value="Home Insurance">Home Insurance</option>
            <option value="Travel">Travel</option>
            <option value="Group Health">Group Health</option>
            <option value="Group Life">Group Life</option>
            <option value="Fire">Fire</option>
            <option value="Property">Property</option>
            <option value="Director & Officers Liability">Director & Officers Liability</option>
            </select>}

            <input onChange={(e) => handle(e)} id="Phone" value={data.Phone} class="form_phone" type="tel" name="email" required placeholder="Enter Mobile Number"  pattern="[0-9]{10}" />

            <button className='tracking-button'>Get Started</button>

            <input type="checkbox" name="acceptance" id="acceptance" class="accept-chkbox" checked></input>
            <label for="form-checkbox"> I agree to the Terms & Conditions</label><br/>
            <input type="checkbox" name="acceptance" id="acceptance" class="accept-chkbox" checked></input>
            <label for="form-checkbox"> I would like to receive information on insurance products via WhatsApp, Email, SMS, phone from Samavesh</label><br/>


          </form>}

          {insuranceFormSuccess &&<div><p>Your form is successfully submitted. Our representative will get in touch with you shortly.</p></div> }
        </div>
      </div>
    </div>
  );
}

export default Form;
