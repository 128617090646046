import React from "react";
import Home from "./Layouts/Home/Home";
import { Switch, Route } from "react-router-dom";
import BecomePOSP from "./Layouts/BecomPOSP/become-posp-insurance-agent";
import InsurancePlans from "./Layouts/InsurancePlans/insurance-plans";
import ContactUs from "./Layouts/ContactUs/contact-us";
import Policy from "./Layouts/PrivacyPolicy/privacy-and-policy";
import Terms from "./Layouts/PrivacyPolicy/terms-and-condition";
import Aboutus from "./Layouts/Aboutus/about-us";
import Posts from "./Layouts/Posts/posts";
import PostPage from "./Layouts/Posts/PostPage";
import DemoPosp from "./Layouts/DemoPOSP/DemoPosp";
import ThankYou from "./Layouts/ThankYou/ThankYou";
import BecomePOSPLead from "./Layouts/BecomePOSPLead/BecomePOSPLead";
class App extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/other" component={Home} />
        <Route exact path="/become-posp-insurance-agent" component={BecomePOSP} />
        <Route exact path="/insurance-plans" component={InsurancePlans} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/about-us" component={Aboutus} />
        <Route exact path="/privacy-and-policy" component={Policy} />
        <Route exact path="/terms-and-condition" component={Terms} />
        <Route exact path="/posts" component={Posts} />
        <Route exact path="/post/:id" component={PostPage} />
        <Route exact path="/demo-posp" component={DemoPosp} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/become-posp" component={BecomePOSPLead} />



      </Switch>
    );
  }
}

export default App;
