import React, { Component } from 'react'
import axios from 'axios'
import PostItem from './PostItem';
import './post.css'         

export class posts extends Component {
  state = {
    post: [],
    isLoaded: false
  }
  
  componentDidMount(){
    axios.get('/wp-json/wp/v2/posts')
        .then(res => this.setState({
          post: res.data,
          isLoaded:true
        }))
        .catch(err => console.log(err));
  }

  render() {
    const {post, isLoaded } = this.state;
    if(isLoaded){
      return (
        <main>
          <div id="post-block">
            <div className="container">
              <div className="col-lg-8">
                <div className="postblock-grid">
                  {post.map(post =>(
                     <PostItem key={post.id} post={post} />

                  ))}
                </div>
             </div>
                <div className="col-lg-4">
                   <div className="card-blogcat">
                      <h2>Blog Categories</h2>
                     </div>
                    </div>
              </div>
            </div>
        </main>
      );
   }
   return null;
  }
}

export default posts
