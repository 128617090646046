import React, { Component, Fragment } from 'react'
import axios from 'axios'
import './post.css'


export class PostPage extends Component {
          state = {
                    post: [],
                    isLoaded:false
          }

          componentDidMount(){
                    axios.get(`/wp-json/wp/v2/posts/${this.props.match.params.id}`)
                    .then(res => this.setState({
                       post: res.data,
                       isLoaded:true       
                    }))
                    .catch(err => console.log(err));
          }
          render() {
                    
                    const{ post, imgUrl, isLoaded} = this.state;
                   
                    if(isLoaded){
                              return (
                                      <div id="inner-post">
                                                <div className="container">
                                                          <div className="row">
                                                           <div className="col-lg-8">
                                                                     <div className="inner-fullpost">
                                                                     <h1>{post.title.rendered}</h1>
                                                          <img style={{width: '100%'}} src={imgUrl} />
                                           
                                                           <div dangerouslySetInnerHTML={{__html:post.content.rendered}}></div>

                                                                     </div>
                                                           </div>


                                                          </div>
                                                          
                                                </div>

                                      </div>
                                           
                              )
                    }
                    return null;
                    }
                    
}

export default PostPage
