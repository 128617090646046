import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';

export class PostItem extends Component {

          state = {
                    imgUrl:'',
                    isLoaded:false
          }
          static propTypes = {
                    post: PropTypes.object.isRequired
          }

          componentDidMount(){
                    const{featured_media, author} = this.props.post;
               const getImageUrl = axios.get(`/wp-json/wp/v2/media/${featured_media}`);
               const getAuthor = axios.get(`/wp-json/wp/v2/users/${author}`);

               Promise.all([getImageUrl, getAuthor]).then(res =>{
                        this.setState({
                                  imgUrl: res[0].data.media_details.sizes.full.source_url,
                                  author: res[1].data.name,
                                  isLoaded:true
                        })
               });

          }
          render() {
                    const {id, title, post_name} = this.props.post;
                    const{ author, imgUrl, isLoaded} = this.state;
                    if(isLoaded){

                              return (
                                        <div className="postblock-render"> 
                                            <img style={{width: '100%'}} src={imgUrl} />
                                            <Link to={`/post/${id}`}>
                                            <h2>{title.rendered}</h2></Link> 
                                            <h3> Review by <strong>{author}</strong></h3>     
                                        </div>
                              );
                    }
                       return null;
                    }
                    
}

export default PostItem
